<template>

  <el-container>
    <el-main class="left-main">
      <el-header>
        <div class="el-icon-arrow-left" @click="returnOn">返回</div>
        <div class="title-body">
          <div class="el-icon-document"></div>
          <div class="text">服务账单</div>
        </div>
        <div class="user-box">
          <div class="user-card">
            <div class="user">
              <div class="avatar">
                <img src="@/assets/images/avatar.png" alt="">
              </div>
              <div class="info">
                <div class="top">
                  <div class="name-tel">
                    <div class="name">
                      赵晓梅
                      <div class="btn">
                        钻石会员
                      </div>
                    </div>
                    <div class="tel">18088880000</div>
                  </div>

                </div>
              </div>
            </div>
            <div class="rem-bind" @click="jumpVipInfo">查看会员信息<span class="el-icon-arrow-right"></span></div>
          </div>
        </div>
      </el-header>
      <div class="body">
        <div class="body-title el-icon-document">基本信息</div>
        <div class="cell">
          <div class="cell-list">
            <div class="cl-left">账单编号</div>
            <div class="cl-right">
              <span class="btn">退货订单</span>
              ZD202102016600</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">结算时间</div>
            <div class="cl-right">2021/01/31 12:00</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">收银人员</div>
            <div class="cl-right">顾小敏</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">收银机号</div>
            <div class="cl-right">A123</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">消费项目</div>
            <div class="cl-right">共5项</div>
          </div>
          <div class="cell-list">
            <div class="cl-left">会员顾问</div>
            <div class="cl-right">顾小敏</div>
          </div>
        </div>
      </div>
    </el-main>
    <el-main class="right-main">
      <el-header>
        <div class="btn">
          <div :class="tabsChange==='1'?'btn1 left':'left'" @click="tabsChange='1'">消费项目</div>
          <div :class="tabsChange==='2'?'btn1 right':'right'" @click="tabsChange='2'">结账详情</div>
        </div>
      </el-header>
      <el-container class="right-main-container">
        <el-main>
          <template v-if="tabsChange==='1'">
            <div class="list" v-for="(item,i) in 15" :key="i">
              <div class="left">
                <div class="name">菲灵魔法定型发胶</div>
                <div class="time">500ml</div>
              </div>
              <div class="center">
                <div class="name">
                  <span>购买数量：</span>
                  <span>2</span>
                </div>
              </div>
              <div class="right">
                <b>¥ 99.00</b>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="end-pay-detail">
              <div class="cell">
                <div class="left">费用合计</div>
                <div class="right"><b>¥ 599.00</b></div>
              </div>
              <div class="cell">
                <div class="left">会员满200打9折活动</div>
                <div class="right">-<b>¥ 30.00</b></div>
              </div>
              <div class="cell">
                <div class="left">满200减20优惠券</div>
                <div class="right">-<b>¥ 20.00</b></div>
              </div>
              <div class="cell">
                <div class="left">500积分抵现</div>
                <div class="right">-<b>¥ 10.00</b></div>
              </div>
              <div class="cell">
                <div class="left">余额支付</div>
                <div class="right">-<b>¥ 10.00</b></div>
              </div>
              <div class="cell">
                <div class="left">整单抹零</div>
                <div class="right">-<b>¥ 10.00</b></div>
              </div>
              <div class="cell">
                <div class="left">支付方式</div>
                <div class="right">支付宝</div>
              </div>
            </div>
            <div class="end-pay-detail">
              <div class="cell">
                <div class="left">退货时间</div>
                <div class="right">2021/01/30 12:00</div>
              </div>
              <div class="cell">
                <div class="left">退货数量</div>
                <div class="right">5</div>
              </div>
              <div class="cell">
                <div class="left">退款金额</div>
                <div class="right"><b>¥ 179.00</b></div>
              </div>
              <div class="cell">
                <div class="left">退款方式</div>
                <div class="right">退回原支付渠道</div>
              </div>
              <div class="cell">
                <div class="left">操作人员</div>
                <div class="right">顾小敏</div>
              </div>
            </div>
          </template>
        </el-main>
        <el-footer>
          <div class="foot-box">
            <div class="left">
              实付金额：<b>¥519.00</b>
            </div>
            <div class="right">
              <div class="btn">补打小票</div>
              <div class="btn" @click="returnGoodsShow=true">退货</div>
            </div>
          </div>
        </el-footer>
      </el-container>
    </el-main>
    <el-drawer class="return-drawer" size="80%" :show-close="false" :visible.sync="returnGoodsShow" direction="rtl"
      :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <div class="left"></div>
          <div class="center">退货处理</div>
          <div class="right el-icon-close" @click="returnGoodsShow = false"></div>
        </div>
      </template>
      <el-container style="height:calc(100vh - 40px);">
        <el-main class="drawer-left-main">
          <div class="search">
            <el-input placeholder="请输入会员姓名、备注名、编号或手机" prefix-icon="el-icon-search" v-model="searchVal">
            </el-input>
            <el-button type="primary">搜索</el-button>
          </div>
          <div class="list" v-for="(item,i) in 15" :key="i">
            <div class="left">
              <div class="name">菲灵魔法定型发胶</div>
              <div class="time">500ml</div>
            </div>
            <div class="center">
              <div class="name">
                <span>购买数量：</span>
                <span>2</span>
              </div>
            </div>
            <div class="right">
              ¥ 99.00
            </div>
          </div>
        </el-main>
        <el-main class="drawer-right-main">
          <el-container style="height:calc(100vh - 40px);">
            <el-header style="height:auto;">
              <el-steps :active="activeStep" align-center>
                <el-step title="选择退货商品"></el-step>
                <el-step title="确认退货信息"></el-step>
                <el-step title="完成退货"></el-step>
              </el-steps>
            </el-header>
            <el-main>
              <template>
                <div v-if="activeStep===1" class="change-out-goods">
                  <div class="out-title">
                    <b>第一步 选择退货商品</b>
                  </div>
                  <div class="out-body">
                    <div class="out-card" v-for="(item ,i) in 13" :key="i">
                      <div class="out-cell top">
                        <div class="out-left name">菲灵魔法定型发胶</div>
                        <div class="out-right el-icon-circle-close"></div>
                      </div>
                      <div class="out-cell ctr">
                        <div class="out-left size">500ml</div>
                        <div class="out-right price">¥99.00 <span>¥99.00</span></div>
                      </div>
                      <div class="out-cell btm">
                        <div class="out-left">购买数量：<span>2</span></div>
                        <div class="out-right">退货数量：
                          <div class="stepper">
                            <el-input-number v-model="stepperNum" :precision="0" :min="1" :max="10">
                            </el-input-number>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="activeStep===2" class="change-out-goods">
                  <div class="out-title">
                    <b>第二步 确认退货信息</b>
                    <div class="num">
                      退货商品(3)
                    </div>
                  </div>
                  <div class="out-body">
                    <div class="cir-out-card" v-for="(item ,i) in 3" :key="i">
                      <div class="cir-left">
                        <div class="name">菲灵魔法定型发胶</div>
                        <div class="size">500ml</div>
                      </div>
                      <div class="cir-right">
                        退货数量：2
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-main>
            <el-footer style="height:auto;">
              <el-collapse v-model="activeName" accordion>
                <el-collapse-item name="1">
                  <template slot="title">
                    <div class="title" v-if="activeName==='1'">收起明细</div>
                    <div class="title" v-else>展开明细</div>
                  </template>
                  <div class="cell">
                    <div class="left">退款合计：</div>
                    <div class="right"><b>¥199.00</b></div>
                  </div>
                  <div class="cell">
                    <div class="left">优惠均摊：</div>
                    <div class="right"><b>¥20.00</b></div>
                  </div>
                  <div class="cell">
                    <div class="left">应退金额：</div>
                    <div class="right"><b>¥179.00</b></div>
                  </div>
                </el-collapse-item>
              </el-collapse>
              <div v-if="activeStep===1" class="foot-btm">
                <div class="text">
                  <span>共选择<b>2</b>项</span>
                  <span>实退金额：<b>¥ 179.00</b></span>
                  <span class="el-icon-edit" @click="edirPriceShow=true">修改</span>
                </div>
                <div class="btn" @click="activeStep=2">下一步</div>
              </div>
              <div v-if="activeStep===2" class="foot-btm1">
                <div class="text">
                  <span>共选择<b>2</b>项</span>
                  <span>实退金额：<b>¥ 179.00</b></span>
                </div>
                <div class="btn-box">
                  <div class="btn btn1" @click="activeStep=1">返回修改</div>
                  <div class="btn btn2" @click="returnGoodsShow=false,outGoodsTypeShow=true">确定退货</div>
                </div>
              </div>
            </el-footer>
          </el-container>
        </el-main>
      </el-container>
      <!-- 修改实退金额 -->
      <el-dialog title="修改实退金额" :visible.sync="edirPriceShow" center append-to-body>

        <el-form ref="form" :model="form" label-width="80px">
          <el-form-item label="应退金额">
            <el-input v-model="form.oldMoney" readonly></el-input>
          </el-form-item>
          <el-form-item label="实退金额">
            <el-input v-model="form.newMoney" placeholder="修改价格"></el-input>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="edirPriceShow = false">取 消</el-button>
          <el-button type="primary" @click="edirPriceShow = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-drawer>
    <el-drawer class="return-type" size="50%" :show-close="false" :visible.sync="outGoodsTypeShow" direction="rtl"
      :before-close="handleClose">
      <template slot="title">
        <div class="drawer-title">
          <div class="left"></div>
          <div class="center">退货处理</div>
          <div class="right el-icon-close" @click="returnGoodsShow = false"></div>
        </div>
      </template>
      <el-container style="height:calc(100vh - 40px);">
        <el-main class="drawer-main">
          <div class="top">
            <div class="el-icon-circle-check"></div>
            <div class="text1">退货成功</div>
            <div class="text2">您可以在账单详情里查看退货信息</div>
          </div>
          <div class="ctr">
            <div class="cell">
              <div class="left">退款全额</div>
              <div class="left"><b>¥179.00</b></div>
            </div>
            <div class="cell">
              <div class="left">退款方式</div>
              <div class="left">支付宝(退回至原支付渠道)</div>
            </div>
          </div>
          <div class="btm">
            <div class="btn" @click="outGoodsTypeShow=false">确定</div>
          </div>

        </el-main>
      </el-container>

    </el-drawer>
  </el-container>
</template>

<script>
  export default {
    data() {
      return {
        currentPage: '',
        routerPage: '',
        tabsChange: '1',
        returnGoodsShow: false,
        searchVal: '',
        activeStep: 1,
        stepperNum: 1,
        activeName: 0,
        edirPriceShow: false,
        form: {
          oldMoney: '1',
          newMoney: '',
        },
        outGoodsTypeShow: false,
      }
    },
    mounted() {
      this.currentPage = this.$route.path

      this.routerPage = window.sessionStorage.getItem('reservationPath')
    },
    destroyed() {
      window.sessionStorage.setItem('reservationPath', '/reservation')
    },
    methods: {
      // 返回上级
      returnOn() {
        this.$router.push({
          path: this.routerPage
        })
        // this.$router.go(-1)
      },
      jumpVipInfo() {
        this.$router.push({
          name: 'VipInfo',
        })
        window.sessionStorage.setItem('returnPage', this.currentPage)
      },
      handleClose() {
        this.returnGoodsShow = false
      }
    }
  };

</script>
<style lang="less" scoped>
  /deep/ .el-container {
    height: calc(100vh);
  }

  /deep/ .left-main.el-main {
    width: 40%;
    min-width: 350px;
    height: calc(100vh);
    padding: 0 !important;

    .el-header {
      position: relative;
      height: auto !important;
      background-color: #1891ff;
      color: #fff;
      padding: 0 !important;

      .el-icon-arrow-left {
        line-height: 40px;
        padding: 0 15px;
      }

      .title-body {
        text-align: center;
        padding: 60px 0 130px;

        .el-icon-document {
          font-size: 120px;
          transform: rotateX(180deg);
        }

        .text {
          font-weight: bold;
          font-size: 18px;
          margin-top: 15px;
        }
      }

      .user-box {
        position: absolute;
        bottom: -25px;
        width: 100%;
        box-sizing: border-box;

        .user-card {
          margin: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px;
          background-color: #fff;
          border-radius: 5px;
          color: #666;
          box-shadow: 0 0 4px #ccc;

          .user {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .avatar {
              width: 35px;
              height: 35px;
              margin-right: 10px;
              margin-top: 2px;

              img {
                width: 100%;
                height: 100%;
                border-radius: 100%;
              }
            }

            .info {
              .top {
                display: flex;
                justify-content: flex-start;

                .name-tel {
                  margin-right: 20px;
                  line-height: 20px;

                  .name {
                    font-weight: bold;

                    .btn {
                      display: inline-block;
                      font-size: 12px;
                      background-color: #ff9900;
                      line-height: 24px;
                      width: 20%;
                      min-width: 60px;
                      text-align: center;
                      border-radius: 5px;
                      color: #fff;
                      margin-left: 20px;
                    }
                  }

                  .tel {
                    font-size: 12px;
                  }
                }


              }
            }
          }

          .rem-bind {
            font-size: 12px;
            color: #999;
          }
        }
      }

    }

    .body {
      margin-top: 35px;

      .body-title {
        padding: 25px 20px;
        font-weight: bold;
        color: #666;

        &::before {
          color: #ff9900;
          margin-right: 5px;
        }
      }

      .cell {
        .cell-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 25px;

          .cl-left {
            color: #999;
          }

          .cl-right {
            .btn {
              font-size: 12px;
              color: #ff9900;
              border: 1px solid #ff9900;
              padding: 2px 5px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  /deep/ .right-main.el-main {
    width: 60%;
    height: calc(100vh);
    padding: 0 !important;

    .el-header {
      display: flex;
      justify-content: center;
      align-items: center;

      .btn {

        .left,
        .right {
          display: inline-block;
          width: 100px;
          height: 30px;
          border: 1px solid #409eff;
          text-align: center;
          line-height: 30px;
          color: #409eff;
          cursor: pointer;
        }

        .left {
          border-right: 0;
          border-radius: 5px 0 0 5px;
        }

        .right {
          border-radius: 0 5px 5px 0;
        }

        .btn1 {
          border: 1px solid #409eff;
          text-align: center;
          line-height: 30px;
          color: #fff;
          background-color: #409eff;
        }

      }
    }

    .right-main-container {
      height: calc(100vh - 60px);
      background-color: #f5f5f5;

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-top: 15px;

        &:last-child {
          margin-bottom: 15px;
        }


        .left {
          .name {
            margin-bottom: 5px;
          }

          .time {
            font-size: 12px;
            color: #999;
          }
        }

        .center {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .name {

            span {
              margin-left: 10px;

              &:first-child {
                color: #999;

              }
            }
          }
        }

        .right {
          color: #f56c6c;
        }
      }

      .end-pay-detail {
        background-color: #fff;
        margin-top: 15px;
        padding: 15px;
        border: 1px solid #f2f2f2;

        .cell {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;
          color: #666;
        }
      }

    }

    .el-footer {
      padding: 15px;
      background-color: #fff;
      border: 1px solid #f2f2f2;
      height: auto;

      .foot-box {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          b {
            color: #f56c6c;
          }
        }

        .right {
          display: flex;
          justify-content: flex-end;

          .btn {
            border: 1px solid #409eff;
            color: #409eff;
            width: 100px;
            text-align: center;
            line-height: 35px;
            border-radius: 30px;

            &:first-child {
              margin-right: 5px;
            }
          }
        }
      }
    }

  }

  /deep/ .el-drawer {
    .el-drawer__header {
      padding: 0;
      margin: 0;
    }

    .drawer-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      border-bottom: 1px solid #f2f2f2;

      .right {
        &::before {
          font-size: 18px;
          color: #409eff;
          font-weight: bold;
        }
      }
    }
  }

  /deep/ .return-drawer {
    .el-drawer {
      min-width: 600px;
    }
  }

  .return-drawer {
    min-width: 600px;
    font-size: 12px;

    .drawer-left-main {
      width: 50%;
      font-size: 12px;
      background-color: #f9f9f9;

      .search {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 20px;

        .el-button--primary {
          height: 35px;
          margin-left: -6px;
          z-index: 1;
          font-size: 12px;
          border-radius: 0 5px 5px 0;

        }

        .el-button--primary:focus,
        .el-button--primary:hover,
        .el-button:active,
        .el-button:focus,
        .el-button:hover {
          color: #fff;
          border-color: #409eff;
          background-color: #409eff;
        }

        /deep/ .el-input__inner {
          height: 35px;
          border: 1px solid #dcdfe6;
          border-right: 0;
          border-radius: 5px 0 0 5px;

          &:focus,
          &:hover {
            border-color: #dcdfe6;
          }
        }

      }

      .list {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        border-radius: 5px;
        padding: 10px;
        margin-top: 15px;
        border: 1px solid #f2f2f2;

        &:last-child {
          margin-bottom: 15px;
        }


        .left {
          .name {
            margin-bottom: 5px;
            font-size: 14px;
          }

          .time {
            font-size: 12px;
            color: #999;
          }
        }

        .center {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .name {

            span {
              margin-left: 10px;

              &:first-child {
                color: #999;

              }
            }
          }
        }

        .right {
          color: #f56c6c;
        }
      }
    }

    .drawer-right-main {
      width: 50%;
      color: #666;
      padding: 0 !important;

      /deep/ .el-steps {
        margin: 20px 0;

        .el-step__head {
          .el-step__icon.is-text {
            border: 1px solid;

            .el-step__icon-inner {
              font-weight: normal;
            }
          }
        }

        .el-step__main {
          .el-step__title.is-process {
            font-weight: normal;
          }

          .el-step__title {
            font-size: 12px;
          }
        }
      }

      .change-out-goods {
        padding: 0 10px;

        .out-title {
          font-size: 16px;
          margin-bottom: 15px;

          .num {
            margin-top: 10px;
            font-size: 14px;
          }
        }

        .out-body {
          .out-card {
            border: 1px solid #e9e9e9;
            border-radius: 8px;
            padding: 10px;
            margin-bottom: 15px;

            .out-cell {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .size,
              .price {
                color: #999;
                font-size: 12px;
              }

              .el-icon-circle-close {
                font-size: 18px;
                color: #ccc;
              }

              .name {
                font-size: 14px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .out-left,
              .out-right {
                span {
                  color: #333;
                  font-size: 14px;
                  margin-left: 5px;
                }
              }

              .out-right {
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }
            }

            .top {
              margin-bottom: 10px;
            }

            .ctr {
              margin-bottom: 15px;
            }

            .btm {
              color: #999;
            }
          }

          .cir-out-card {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;
            padding: 5px 10px;
            border: 1px solid #e9e9e9;
            border-radius: 8px;
            line-height: 24px;

            .size {
              color: #999;
            }
          }
        }


      }

      /deep/ .el-footer {
        border-top: 1px solid #e9e9e9;
        padding: 0;

        .el-collapse {
          border: none;

          .el-collapse-item__header {
            border-bottom: none;
            justify-content: center;
            color: #ccc;
            line-height: 38px;
            height: auto;

            .el-collapse-item__arrow {
              margin: 0;
            }
          }

          .el-collapse-item__content {
            padding-bottom: 10px;

            .cell {
              display: flex;
              justify-content: space-between;
              line-height: 30px;
              font-size: 12px;
              color: #666;
            }

          }
        }

        .foot-btm {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 12px;
          line-height: 30px;
          padding: 0 5px 10px;

          span {
            margin-right: 8px;

            b {
              color: #f56c6c;
            }
          }

          .btn {
            background-color: #409eff;
            color: #fff;
            border-radius: 30px;
            min-width: 60px;
            width: 20%;
            text-align: center;
          }
        }

        .foot-btm1 {
          text-align: right;

          span {
            margin-right: 10px;

            b {
              color: #f56c6c;
            }
          }

          .btn-box {
            display: flex;
            justify-content: space-around;
            align-items: center;
            padding: 10px 0;

            .btn {
              width: 40%;
              border-radius: 50px;
              text-align: center;
              border: 1px solid #409eff;
              color: #409eff;
              line-height: 30px;

              &:last-child {
                background-color: #409eff;
                color: #fff;
              }
            }
          }
        }
      }
    }


  }

  /deep/ .el-dialog {
    min-width: 400px;

    .el-form {
      .el-form-item {
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        overflow: hidden;

        .el-input {
          .el-input__inner {
            border: none;
          }
        }
      }
    }
  }

  /deep/ .return-type {
    .el-drawer {
      min-width: 400px;

      .drawer-main {
        .top {
          text-align: center;
          padding: 50px 0;

          .el-icon-circle-check {
            font-size: 72px;
            color: #19be6b;
            margin-bottom: 20px;
          }

          .text1 {
            font-size: 16px;
            font-weight: bold;
            color: #666;
            margin-bottom: 10px;
          }

          .text2 {
            font-size: 12px;
            color: #999;
          }
        }

        .ctr {
          .cell {
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 30px;
            color: #666;
          }
        }

        .btm {
          display: flex;
          justify-content: center;
          margin-top: 40px;

          .btn {
            width: 40%;
            background-color: #409eff;
            color: #fff;
            text-align: center;
            line-height: 40px;
            border-radius: 8px;
          }
        }
      }
    }
  }


  .stepper {

    /deep/ .el-input-number {
      width: 90px;
      height: 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .el-input-number__decrease,
      .el-input-number__increase {
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        border: 1px solid #ccc;
        color: #ccc;
        font-weight: bold;
        background-color: #fff;
      }

      .el-input {
        width: 90px;
        height: 34px;
        background-color: transparent;

        .el-input__inner {
          height: 20px;
          line-height: 20px;
          padding: 0 15px;
          border-color: #73a0fa;
          background-color: transparent;
          color: #999;
          font-weight: bold;
          border: none;
        }
      }
    }
  }

</style>
